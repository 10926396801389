import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { apiUrl } from './helpers/Global';

class Reset extends Component {

    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            error: null,
            success: null,
            inputs: {},
            password_form: false
        };
    }

    handleSubmit(event) {
        event.preventDefault();
        var inputs = this.state.inputs;
        var btn = document.getElementById('btnSubmit');
        btn.classList.add('loading');
        axios.post(apiUrl(`users/reset`), inputs).then(res => {
            var data = res.data;
            if ( data.error ) {
                this.setState({ 
                    error: data.message,
                    success: null
                });
            } else {
                this.setState({ 
                    password_form: true,
                    error: null,
                    success: data.message,
                });
            }
            btn.classList.remove('loading');
        });
    }

    render() {
        return (
            <div id='app-root'>
                <div className='content' id='reset'>
                    <div className='container'>
                        <div className='page-title'><h2>Mot de passe oublié</h2></div>
                        <form className='form' onSubmit={this.handleSubmit}>

                            <p>Veuillez saisir l'adresse e-mail associée à votre compte d'utilisateur. Un code de vérification vous sera adressé. Lorsque vous le recevrez, vous pourrez choisir un nouveau mot de passe.</p>

                            <div className='form-group'>
                                <label>E-mail <small className='red'>(*)</small></label>
                                <input type='email' name='email' className='form-control' onChange={this.inputHandling} required />
                            </div>

                            { this.state.password_form && (
                                <div className='clearfix'><hr/>

                                    <div className='alert alert-primary'>Un e-mail a été envoyé à votre adresse e-mail. Cet e-mail contient un code de vérification : veuillez coller ce code dans le champ ci-dessous pour pouver que vous êtes bien le détenteur de ce compte.</div>

                                    <div className='form-group'>
                                        <label>Code de vérification <small className='red'>(*)</small></label>
                                        <input type='text' name='code' className='form-control' onChange={this.inputHandling} required />
                                    </div>
                                    <div className='form-group'>
                                        <label>Nouveau mot de passe <small className='red'>(*)</small></label>
                                        <input type='password' name='password' className='form-control' onChange={this.inputHandling} required />
                                    </div>
                                    <div className='form-group'>
                                        <label>Mot de passe confirmation <small className='red'>(*)</small></label>
                                        <input type='password' name='password_confirmation' className='form-control' onChange={this.inputHandling} required />
                                    </div>
                                </div>
                            ) }
                            
                            { this.state.error && (<div className='alert alert-danger'>{ this.state.error }</div>) }
                            { this.state.success && (<div className='alert alert-success'>{ this.state.success }</div>) }

                            <div className='form-group mt-4'>
                                <button type='submit' className='btn w-100' id='btnSubmit'>Envoyer</button>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        );
    }

    inputHandling = (event) => {
        var inputs = this.state.inputs;
        var target = event.target;
        inputs[target.name] = target.value;
        this.setState({ inputs: inputs });
    }

}

export default withRouter(Reset);
