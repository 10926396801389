import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Footer extends Component
{

    constructor(props) {
        super(props);
        this.state = {
            auth_user: props.auth_user
        };
    }

    render() {
        var auth_user = this.state.auth_user || {};
        var notifs_count = this.props.notifs_count || 0; 
        notifs_count = 0;

        if ( auth_user.user_type === 'livreur' ) {
            return (
                <div id="footer">
                    <div className="tab">
                        <Link to="/notifications" replace className='txt'>
                            <img src={require('../assets/images/icons/bell.svg')} className='img' alt='Notifications' />
                            Notifications
                            { notifs_count > 0 && (<span className='footer-notif'>({notifs_count})</span>) }
                        </Link>
                    </div>
                    <div className="tab">
                        <Link to="/indelivery" replace className='txt'>
                            <img src={require('../assets/images/icons/truck.svg')} className='img' alt='En livraison' />
                            En livraison
                        </Link>
                    </div>
                    <div className="tab">
                        <Link to="/commands" replace className='txt'>
                            <img src={require('../assets/images/icons/shopping-bag.svg')} className='img' alt='Mes commandes' />
                            Livraisons
                        </Link>
                    </div>
                    <div className="tab">
                        <Link to="/support" replace className='txt'>
                            <img src={require('../assets/images/icons/mail.svg')} className='img' alt='Contact' />
                            Support
                        </Link>
                    </div>
                </div>
            );
        } else if ( auth_user.user_type === 'pro' ) {
            return (
                <div id="footer">
                    <div className="tab">
                        <Link to="/command/create" replace className='txt'>
                            <img src={require('../assets/images/icons/plus-square.svg')} className='img' alt='Nouvelle commande' />
                            Nouvelle
                        </Link>
                    </div>
                    <div className="tab">
                        <Link to="/notifications" replace className='txt'>
                            <img src={require('../assets/images/icons/bell.svg')} className='img' alt='Notifications' />
                            Notifications
                            { notifs_count > 0 && (<span className='footer-notif'>({notifs_count})</span>) }
                        </Link>
                    </div>
                    <div className="tab">
                        <Link to="/commands" replace className='txt'>
                            <img src={require('../assets/images/icons/shopping-bag.svg')} className='img' alt='Mes demandes' />
                            Demandes
                        </Link>
                    </div>
                    <div className="tab">
                        <Link to="/support" replace className='txt'>
                            <img src={require('../assets/images/icons/mail.svg')} className='img' alt='Contact' />
                            Support
                        </Link>
                    </div>
                </div>
            );
        } else {
            return (
                <div id="footer">
                    <div className="tab">
                        <Link to="/" replace className='txt'>
                            <img src={require('../assets/images/icons/home.svg')} className='img' alt='Accueil' />
                            Accueil
                        </Link>
                    </div>
                    <div className="tab">
                        <Link to="/inscription-commercant" replace className='txt'>
                            <img src={require('../assets/images/icons/user-plus.svg')} className='img' alt='inscrire' />
                            S'inscrire
                        </Link>
                    </div>
                    <div className="tab">
                        <Link to="/login" replace className='txt'>
                            <img src={require('../assets/images/icons/alert-circle.svg')} className='img' alt='Se connecter' />
                            Se connecter
                        </Link>
                    </div>
                    <div className="tab">
                        <Link to="/contact" replace className='txt'>
                            <img src={require('../assets/images/icons/mail.svg')} className='img' alt='Contact' />
                            Contact 
                        </Link>
                    </div>
                </div>
            );
        }

    }

}

export default Footer;
