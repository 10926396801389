import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import axios from 'axios';
import { apiUrl, dateFormat } from './../helpers/Global';

class Support extends Component 
{

    constructor(props) {
        super(props);
        this.state = {
            auth_user: props.auth_user,
            messages: null,
            inputs: {}
        };
    }

    componentDidMount() {
        axios.get(apiUrl(`support`)).then(res => {
			this.setState({ messages: (res.data || []) });
		});
    }

    render() {
		return (
			<div id='support'>
				<div className='page-title'><h2>Support</h2></div>
				<div className='form-group text-center' style={{ margin: '15px auto' }}>
					<Link to='/support/create' replace className='btn w-100'>Nouveau message</Link>
				</div>
				{
					this.state.messages &&
					this.state.messages.map(msg =>
						<div key={msg.id} className="notif">
							<small>{ dateFormat(msg.created_at)  }</small>
							<p>{ msg.subject }</p>
							<div>
								<Link to={`/supports/${msg.id}`} replace className='link'>Voir</Link>
							</div>
						</div>
					)
				}

				{
					this.state.messages && !this.state.messages.length &&
					<p className='text-muted text-center'>Aucun message !</p>
				}
			</div>
		);
    }

    inputHandling = (event) => {
        var inputs = this.state.inputs;
        var target = event.target;
        inputs[target.name] = target.value;
        this.setState({ inputs: inputs });
    }

}

export default withRouter(Support);
