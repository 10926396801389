import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import axios from 'axios';
import { apiUrl } from './../helpers/Global';

class Commands extends Component
{

    constructor(props) {
        super(props);
        this.state = {
            auth_user: props.auth_user,
            commands: [],
        };
    }

    componentDidMount() {
        axios.get(apiUrl(`commands`)).then(res => {
            const data = res.data;
            if ( data.error ) {
                this.setState({ error: data.message });
            } else {
                this.setState({ commands: data });
            }
        });
    }

    render() {
        var commands = this.state.commands || [];
        var auth_user = (this.state.auth_user || {});
        return (
            <div id='commands'>
                <div className='page-title'><h2>{ auth_user.user_type==='livreur' ? 'Mes livraisons' : 'Mes demandes' }</h2></div>
                { commands.length ? (
                    <div className='table-responsive'>
                        <table className='table small list'>
                            <tbody>
                                { commands.map((cmd) =>
                                    <tr key={cmd.id}>
                                        <td>{cmd.num}</td>
                                        <td><small>{cmd.created_at}</small></td>
                                        <td>
                                            <Link to={`/commands/${cmd.id}`} className='link'>Voir</Link>
                                        </td>
                                    </tr>
                                )}
                                
                            </tbody>
                        </table>
                    </div>
                ) : (<p className='text-muted text-center'>-</p>) }
            </div>
        );
    }

}

export default withRouter(Commands);
