import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { apiUrl, authUser } from './helpers/Global';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show_menu: '',
            auth_user: props.auth_user,
            user_status: props.auth_user.user_status?true:false
        };
    }

    render() {
        const auth_user = this.state.auth_user;

        return (
            <div id='header'>
                <Link to='/' replace><img className="logo" src={require('../assets/images/logo.png')} alt='Logo' style={{ height: '50px' }} /></Link>
                <div className={`burger-menu${this.state.show_menu}`} onClick={()=>this.showMenu()}><span></span></div>
                <div className={`menu${this.state.show_menu}`}>
                    { auth_user.id ? (
                        <ul className='nav'>
                            { auth_user.user_type === 'livreur' ? (
                                <li>
                                    <div className={`checkbox switch m-0`}>
                                        <input type='checkbox' id='check_user_status' name='check_user_status' onChange={this.changeUserStatus} checked={auth_user.user_status?true:false} />
                                        <label htmlFor='check_user_status' className='icon'></label>
                                        <label htmlFor='check_user_status' className='txt'>Disponible</label>
                                    </div>
                                </li>
                            ) : '' }
                            <li><Link to='/commands' onClick={()=>this.closeMenu()}>{ auth_user.user_type==='livreur' ? 'Mes livraisons' : 'Mes demandes' }</Link></li>
                            { auth_user.user_type === 'pro' ? (
                                <li><Link to='/command/create' onClick={()=>this.closeMenu()}>Nouvelle demande</Link></li>
                            ) : '' }
                            <li><Link to='/profil' onClick={()=>this.closeMenu()}>Mon profil</Link></li>
                            <li><Link to='/' onClick={()=>this.logout()}>Se déconnecter</Link></li>
                            { auth_user.id === 1 ? (
                                <li><Link to='/users' onClick={()=>this.closeMenu()}>Gérer les utilisateurs</Link></li>
                            ) : '' }
                        </ul>
                    ) : (
                        <ul className='nav'>
                            <li><Link to='/login' onClick={()=>this.closeMenu()}>Se connecter</Link></li>
                            <li><Link to='/inscription-commercant' onClick={()=>this.closeMenu()}>S'inscrire</Link></li>
                        </ul>
                    ) }
                </div>
            </div>
        );
    }

    changeUserStatus = (event) => {
        var auth_user = this.state.auth_user || {};
        var target = event.target;
        var inputs = {};
        this.setState({ user_status: target.checked }, function(){
            axios.post(apiUrl(`users/status`), inputs).then(res => {
                const data = res.data;
                if ( data.user ) {
                    auth_user.user_status = data.user.user_status;
                    authUser().persist(data.user);
                    if (typeof this.props.authenticate === "function") {
                        this.props.authenticate();
                    }
                }
            });
        });
    }

    showMenu = () => {
        let show_menu = this.state.show_menu;
        if ( show_menu === '' ) show_menu = ' active';
        else show_menu = '';
        this.setState({ 
            show_menu: show_menu,
            auth_user: this.props.auth_user
         });
    }

    closeMenu = () => {
        this.setState({ show_menu: '' });
    }

    logout = () => {
        if (typeof this.props.logout === 'function') {
            this.props.logout();
        }
        this.closeMenu();
    }

}

export default Header;
