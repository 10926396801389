import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import '../assets/css/home.css';
import '../assets/scss/home.scss';
import axios from 'axios';
import { apiUrl, trans } from './helpers/Global';

class Home extends Component {

	constructor(props) {
        super(props);
        this.state = {
			inputs: {},
			error_num: null
		};
	}
	
	componentDidMount() {
		if (typeof this.props.setCurrentPath === "function") {
			this.props.setCurrentPath();
		}
	}

    render() {
        return (
            <React.Fragment>

				<div id='app-root'>
					<div id='home' className='content'>
						<div className='container-fluid'>
							<div className='col-sm-6 text-left-inner'>
								<h1>{ trans('La plateforme de') }</h1>
								<h2>{ trans('Livraison rapide') }</h2>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed condimentum, metus a tempor convallis, odio turpis facilisis eros, in luctus nunc nulla in ligula.</p>
								<div className="btns-row">
									<Link to='/inscription-livreur' replace className='btn btn-lg'>Compte livreur</Link>
									<Link to='/inscription-commercant' replace className='btn btn-lg'>Compte commerçant</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="trackingbar">
					<div className="container-fluid">
						<div className="inline-text">
							<span>Suivez votre expédition</span>
						</div>
						<div className="inline-text">
							<div className="input-group input-group-lg input-group-withgap qsearch-form">
								<input type="text" className="form-control" name="reference_number" required="required" id="reference_number" placeholder="LXX-XXXXX" onChange={this.inputHandling} />
								<span className="input-group-btn">
									<button className="btn btn-theme" type="submit" onClick={()=>this.searchCommand()}>Rechercher</button>
								</span>
							</div>
						</div>
						{ this.state.error_num && (<div className='container alert' style={{ fontSize: '25px', color: '#85144b' }}>{this.state.error_num}</div>) }
					</div>
				</div>
				
				<section className="section section-steps">
					<div className="container-fluid">
						{/*<h2 className="section-title">Pourquoi nous choisir ?</h2>*/}
						<div className="section-content">
							<div className="row">
								<div className="step">
									<img src={require('../assets/images/icons/smile.svg')} alt="&nbsp;" />
									<p className="step-number">Simple & pratique</p>
									<p className="desc">Contactez les particuliers de votre choix via la messagerie instantanée de Jwebi, envoyer vos colis n’aura jamais été aussi facile !</p>
								</div>
								<div className="step">
									<img src={require('../assets/images/icons/thumbs-up.svg')} alt="&nbsp;" />
									<p className="step-number">Moins cher</p>
									<p className="desc">Grâce au transport de colis entre particuliers, économisez jusqu’à 70% sur vos frais d’expédition pour vos envois en France et à l’international.</p>
								</div>
								<div className="step">
									<img src={require('../assets/images/icons/zap.svg')} alt="&nbsp;" />
									<p className="step-number">Rapide</p>
									<p className="desc">Pour vos envois urgents, faites appel à des voyageurs dont les trajets ont lieu le jour même ou le lendemain.</p>
								</div>
								<div className="step">
									<img src={require('../assets/images/icons/shield.svg')} alt="&nbsp;" />
									<p className="step-number">Confiance</p>
									<p className="desc">Faites appel à des particuliers et des transporteurs professionnels avec des profils vérifiés pour une livraison de vos colis en toute sérénité.</p>
								</div>
							</div>
						</div>
					</div>
				</section>

            </React.Fragment>
        );
	}

	searchCommand = () => {
        axios.post(apiUrl(`commands/search`), this.state.inputs).then(res => {
			const data = res.data;
			if ( data.message ) {
				this.setState({ error_num: data.message });
			}
		});
	}
	
	inputHandling = (event) => {
        var inputs = this.state.inputs;
        var target = event.target;
        inputs[target.name] = target.value;
        this.setState({ inputs: inputs });
    }

}

export default withRouter(Home);
