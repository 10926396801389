import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import axios from 'axios';
import { apiUrl, dateFormat } from './../helpers/Global';

class SupportShow extends Component 
{

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            auth_user: props.auth_user,
            support_id: props.match.params.id,
            inputs: {},
            message_row: {}
        };
    }

    componentDidMount() {
        axios.get(apiUrl(`support`)+'&id='+this.state.support_id).then(res => {
			this.setState({ message_row: (res.data || {}) });
		});
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ success: ''});
        var inputs = this.state.inputs;
        inputs['parent_id'] = this.state.support_id;
        const form = event.target;
        axios.post(apiUrl(`support/create`), inputs).then(res => {
            const data = res.data;
            if ( data.error ) {
                this.setState({ error: data.message, success: '' });
            } else {
                this.setState({ 
                    success: data.message, 
                    error: '',
                    message_row: (data.message_row || {}) 
                }, function(){
                    form.reset();
                });
            }
        });
    }

    render() {
		const message_row = this.state.message_row;
		const responses = (message_row.responses || []);
		return (
			<div id='support-details'>
				<div className='page-title'><h2>Support</h2></div>
				<div className='form-group text-center' style={{ margin: '15px auto' }}>
					<Link to='/support' replace className='btn w-100'>Liste des messages</Link>
				</div>
				<div className='clearfix'>
					<div className='support-message mb-4 mt-4'>
						<div className='mb-2'>{ message_row.message }</div>
						<span className='small text-muted'>{ dateFormat(message_row.created_at) } | { message_row.user_name }</span>
					</div>
					<div className='support-responses mb-4'>
						{ responses.map(resp =>
							<div className='support-response msg mb-2' key={resp.id}>
								<div className='mb-2'>{ resp.message }</div>
								<span className='small colored'>{ dateFormat(resp.created_at) } | { resp.user_name }</span>
							</div>
						)}
					</div>
					<div className='support-response-form mt-4'>
						<form className='form' method='post' onSubmit={this.handleSubmit}>
							<div className='form-group'>
								<textarea name='message' className='form-control' rows='3' onChange={this.inputHandling} required placeholder='Votre message ici...'></textarea>
							</div>
							<button type='submit' className='btn w-100'>Répondre</button>
						</form>
					</div>
				</div>
			</div>
		);
    }

    inputHandling = (event) => {
        var inputs = this.state.inputs;
        var target = event.target;
        inputs[target.name] = target.value;
        this.setState({ inputs: inputs });
    }

}

export default withRouter(SupportShow);
