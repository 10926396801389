import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import axios from 'axios';
import { apiUrl, authUser } from './helpers/Global';

class Login extends Component {

    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            error: null,
            inputs: {}
        };
    }

    componentDidMount() {
		if (typeof this.props.setCurrentPath === "function") {
			this.props.setCurrentPath();
		}
	}

    handleSubmit(event) {
        event.preventDefault();
        var inputs = this.state.inputs;
        var btn = document.getElementById('btnSubmit');
        btn.classList.add('loading');
        axios.post(apiUrl(`users/login`), inputs).then(res => {
            const data = res.data;
            if ( data.error ) {
                this.setState({ 
                    error: data.message,
                });
            } else if ( data.user ) {
                authUser().persist(data.user);
                if (typeof this.props.authenticate === "function") {
                    this.props.authenticate();
                }
                this.props.history.push(`/`);
            }
            btn.classList.remove('loading');
        });
    }

    render() {
        return (
            <div id='app-root'>
                <div className='content' id='login'>
                    <div className='container'>
                        <div className='page-title'><h2>Identification</h2></div>
                        <form className='form' onSubmit={this.handleSubmit}>
                            <div className='form-group'>
                                <label>E-mail</label>
                                <input type='email' name='login' className='form-control' onChange={this.inputHandling} required />
                            </div>
                            <div className='form-group'>
                                <label>Mot de passe</label>
                                <input type='password' name='password' className='form-control' onChange={this.inputHandling} required />
                            </div>

                            { this.state.error ? (<div className='alert alert-danger'>{ this.state.error }</div>) : ('') }

                            <div className='form-group mt-4'>
                                <button type='submit' className='btn w-100' id='btnSubmit'>Se connecter</button>
                            </div>

                            <table className='text-center'>
                                <tbody>
                                    <tr>
                                        <td className='border-r'><Link to='/reset'>Vous avez oublié votre mot de passe ?</Link></td>
                                        <td><Link to='/inscription-commercant'>Vous n'avez pas encore de compte ?</Link></td>
                                    </tr>
                                </tbody>
                            </table>

                        </form>
                    </div>
                </div>
            </div>
        );
    }

    inputHandling = (event) => {
        var inputs = this.state.inputs;
        var target = event.target;
        inputs[target.name] = target.value;
        this.setState({ inputs: inputs });
    }

}

export default withRouter(Login);
