import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiUrl } from './../helpers/Global';

class CommandsIndelivery extends Component
{

    constructor(props) {
        super(props);
        this.state = {
            auth_user: props.auth_user,
            commands: [],
        };
    }

    componentDidMount() {
        axios.get(apiUrl(`commands`)+'&state=indelivery').then(res => {
            const data = res.data;
            if ( data.error ) {
                this.setState({ error: data.message });
            } else {
                this.setState({ commands: data });
            }
        });
    }

    render() {
        const commands = (this.state.commands || []);
        return (
            <div id='commands'>
                <div className='page-title'><h2>En livraison</h2></div>
                { commands.length ? (
                    <div className='table-responsive'>
                        <table className='table small list'>
                            <tbody>
                                { commands.map((cmd) =>
                                    <tr key={cmd.id}>
                                        <td><small>N° :</small> {cmd.num}</td>
                                        <td><small>{cmd.created_at}</small></td>
                                        <td>
                                            <Link to={`/commands/${cmd.id}`} className='link'>Voir</Link>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                ) : (<p className='text-muted text-center'>Aucune livraison !</p>) }
                
            </div>
        );
    }

}

export default withRouter(CommandsIndelivery);
