import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { apiUrl } from './../helpers/Global';

class UserIndex extends Component 
{

    constructor(props) {
        super(props);
        this.state = {
            auth_user: props.auth_user,
            notifs: [],
            notifs_count: 0,
            commands: [],
        };
    }

    componentDidMount() {
        axios.post(apiUrl(`users/dashboard`)).then(res => {
            const data = res.data;
            if ( data.error_code === 401 ) {
                if (typeof this.props.logout === 'function') {
                    this.props.logout();
                }
            } else {
                this.setState({
                    notifs: data.notifs,
                    notifs_count: data.notifs_count,
                    commands: data.commands,
                });
            }
        });
    }

    render() {
        var auth_user = this.state.auth_user || {};
        if ( auth_user.state !== 1 ) {
            return (
                <div id='user-index'>
                    <p className='text-muted text-center'>
                        Merci pour votre inscription <br />
                        Votre compte est en cours de validation...
                    </p>
                </div>
            );
        } else {
            const commands = this.state.commands || [];
            return (
                <div id='user-index'>

                    { (commands.length === 0 && this.state.notifs_count === 0) ? (
                        <p className='text-muted text-center'>Aucune demande trouvée!</p>
                    ) : '' }

                    { Object.entries(commands).map(([type, cmds], index) =>
                        <div key={index}>
                            <h3>{ type }</h3>
                            <div className='table-responsive'>
                                <table className='table small list'>
                                    <tbody>
                                        { cmds.map((cmd, index) => 
                                        <tr key={index}>
                                            <td>{ cmd.num }</td>
                                            <td><small>{cmd.created_at}</small></td>
                                            <td><Link to={`/commands/${cmd.id}`} className='link'>Voir</Link></td>
                                        </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            );
        }
        
    }

}

export default withRouter(UserIndex);
