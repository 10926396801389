import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

class RegisterApp extends Component 
{

    render() {
        return (
            <div id='app-root'>
                <div className='content' id='home'>
                    <div className='container-fluid'>
                        <div className='col-sm-6 text-left-inner'>
                            <h1>Gagnez de l’argent sur chaque trajet</h1>
                            <h2>Téléchargez l’application et commencez a livrer</h2>
                            <div className="btns-row">
                                <a href='/' target='_blank' className='link-bordered'>
                                    <img src={require('../assets/images/googleplay.png')} alt='GooglePlay' />
                                </a>
                                <a href='/' target='_blank' className='link-bordered'>
                                    <img src={require('../assets/images/appstore.png')} alt='AppleStore' />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(RegisterApp);
