import React, { Component } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import axios from 'axios';
import './assets/css/app.css';
import './assets/scss/style.scss';
import { authUser, logout, apiUrl } from './components/helpers/Global';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Register from './components/Register';
import Login from './components/Login';
import Reset from './components/Reset';
import UserIndex from './components/user/UserIndex';
import Commands from './components/user/Commands';
import CommandDetails from './components/user/CommandDetails';
import CommandCreate from './components/user/CommandCreate';
import Profil from './components/user/Profil';
import Support from './components/user/Support';
import SupportCreate from './components/user/SupportCreate';
import SupportShow from './components/user/SupportShow';
import Notifications from './components/user/Notifications';
import CommandsIndelivery from './components/user/CommandsIndelivery';
import RegisterApp from './components/RegisterApp';
import RegisterLivreur from './components/RegisterLivreur';
import Conditions from './components/Conditions';
import Contact from './components/Contact';
import UserConnect from './components/UserConnect';
import Users from './components/user/Users';

class App extends Component 
{

	constructor(props) {
        super(props);
        this.state = {
			auth_user: (authUser().get() || {}),
			notifs: [],
			notifs_count: 0,
			map: null,
			marker: null,
			latitude: null,
			longitude: null,
			currentPath : null
		};
	}

	componentDidMount() {
		/*const map_div = document.getElementById('map');
		if ( map_div ) {
			const script = document.createElement('script');
			script.src = process.env.PUBLIC_URL + '/sdk/tomtom.min.js';
			document.body.appendChild(script);
			script.async = true;
			script.onload = () => {
				var map = window.tomtom.L.map('map', {
					source: 'vector',
					key: 'bYor6q8GwlpYMl6c6vLeFGcAwGPS73zQ',
					center: [30.412020, -9.564583],
					basePath: '/sdk',
					zoom: 16,
					dragging: false,
					touchZoom: false,
					scrollWheelZoom: false,
					doubleClickZoom: false,
					boxZoom: false,
					tap: false,
					keyboard: false,
					zoomControl: false
				});
				this.setCommandPosition(map);
			}
		}*/
		//this.checkForNotications();
		//this.setDelivererPosition();

		//if ( navigator.geolocation ) navigator.geolocation.getCurrentPosition(function(){});
	}

	render() {
		if ( this.state.auth_user.id ) {
			return (
				<BrowserRouter>
					<div className='app'>
						<Header 
							auth_user={this.state.auth_user} 
							logout={this.logout} 
							authenticate={this.authenticate}
						/>
						<div id='app-root'>
							<div className='content'>
								<div className='container'>
									<Route exact path='/' render={ () => <UserIndex auth_user={this.state.auth_user} logout={this.logout} notifs={this.state.notifs} notifs_count={this.state.notifs_count} /> } />
									<Route exact path='/commands' render={ () => <Commands auth_user={this.state.auth_user} /> } />
									<Route exact path='/commands/:id' render={ () => <CommandDetails auth_user={this.state.auth_user} /> } />
									<Route exact path='/command/create' render={ () => <CommandCreate auth_user={this.state.auth_user} /> } />
									<Route exact path='/profil' render={ () => <Profil auth_user={this.state.auth_user} authenticate={this.authenticate} /> } />
									<Route exact path='/support' render={ () => <Support auth_user={this.state.auth_user} /> } />
									<Route exact path='/supports/:id' render={ () => <SupportShow auth_user={this.state.auth_user} /> } />
									<Route exact path='/support/create' render={ () => <SupportCreate auth_user={this.state.auth_user} /> } />
									<Route exact path='/notifications' render={ () => <Notifications auth_user={this.state.auth_user} notifs={this.state.notifs} notifs_count={this.state.notifs_count} /> } />
									<Route exact path='/indelivery' render={ () => <CommandsIndelivery auth_user={this.state.auth_user} /> } />
								</div>
								<Route exact path='/users' render={ () => <Users auth_user={this.state.auth_user} /> } />
							</div>
						</div>
						<Footer auth_user={this.state.auth_user} notifs_count={this.state.notifs_count} />
					</div>
				</BrowserRouter>
			);
		} else {
			return (
				<BrowserRouter>
					<div className={`app${window.location.pathname==='/'?' homepage':''}`}>
						<Header auth_user={this.state.auth_user} />

						<Route exact path='/' render={ () => <Home setCurrentPath={this.setCurrentPath} /> } />
						<Route exact path='/livreur-app' render={ () => <RegisterApp /> } />
						<Route exact path='/login' render={ () => <Login authenticate={this.authenticate} setCurrentPath={this.setCurrentPath} /> } />
						<Route exact path='/inscription-commercant' render={ () => <Register authenticate={this.authenticate} setCurrentPath={this.setCurrentPath} /> } />
						<Route exact path='/inscription-livreur' render={ () => <RegisterLivreur authenticate={this.authenticate} setCurrentPath={this.setCurrentPath} /> } />
						<Route exact path='/reset' render={ () => <Reset /> } />
						<Route exact path='/conditions' render={ () => <Conditions /> } />
						<Route exact path='/contact' render={ () => <Contact setCurrentPath={this.setCurrentPath} /> } />
						<Route exact path='/connect/:token' render={ () => <UserConnect authenticate={this.authenticate} /> } />

						<Footer auth_user={this.state.auth_user} notifs_count={this.state.notifs_count} />
					</div>
				</BrowserRouter>
			);
		}
	}

	/**
	* Set deliverer position
	*/
	/*setDelivererPosition = () => {
		const auth_user = this.state.auth_user;
		if ( auth_user.deliverer_id ) {
			if ( navigator.geolocation ) {
				navigator.geolocation.getCurrentPosition(function(position){
					var inputs = {};
					inputs['latitude'] = position.coords.latitude;
					inputs['longitude'] = position.coords.longitude;
					axios.post(apiUrl(`deliverers/setposition`), inputs).then(res => {
						//const data = res.data;
					});
				}, function(error){
					//console.log(error);
				});
				setTimeout(this.setDelivererPosition.bind(this), 20000);
			}
		}
	}*/

	/**
	* Set command position on map
	*/
	/*setCommandPosition = (map) => {
		if ( map ) {
			this.setState({ map: map });
		} else {
			map = this.state.map;
		}
		axios.get(apiUrl(`deliverers/position`)).then(res => {
			const data = res.data;
			if ( data.latitude && data.longitude ) {
				if ( data.latitude !== this.state.latitude || data.longitude !== this.state.longitude ) {
					if ( this.state.marker ) this.state.marker.remove();
					var marker = new window.tomtom.L.Marker().setLatLng([data.latitude, data.longitude]).addTo(map);

					var point = [data.latitude, data.longitude];
					map.setView(point, 16);

					this.setState({
						marker: marker,
						latitude: data.latitude,
						longitude: data.longitude
					});
				}
			}
		});
		setTimeout(this.setCommandPosition.bind(this), 10000);
	}*/

	/**
	* Show web notification
	*/
	checkForNotications = () => {
		if ( this.state.auth_user.id ) {
			axios.post(apiUrl(`users/dashboard`)+'&return=notifs').then(res => {
				const data = res.data;
				this.setState({
					notifs: data.notifs,
					notifs_count: data.notifs_count
				}, function() {
					/*var notifs_count = this.state.notifs_count;
					Notification.requestPermission( function(status) {
						if ( status === 'granted' ) {
							if ( notifs_count > 0 ) {
								new Notification('LivreurApp', {
									'body': 'Nouvelle(s) notification(s)',
									'icon': 'http://localhost:3000/favicon.ico',
									'sound': './assets/alert.mp3'
								});
							}
						}
					});*/
				});
			});
		}
		setTimeout(this.checkForNotications.bind(this), 60000);
	}

	/**
	* Authenticate user
	*/
	authenticate = () => {
        this.setState({
            auth_user: authUser().get()
        }, function(){
			this.forceUpdate();
		});
	}
	
	/**
	* Log out
	*/
	logout = () => {
        logout();
        this.setState({ auth_user: {} });
	}
	
	/**
	* setCurrentPath
	*/
	setCurrentPath = () => {
		this.setState({ currentPath: window.location.pathname });
	}

}

export default App;
