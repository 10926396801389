
/**
* Api url
*/
export function apiUrl(url) {
    const user = (authUser().get() || {});
    let api_url = 'https://superresto.ma/livreur/public/v1/' + url;
    if ( user.api_token ) {
        api_url = (api_url + '?api_token=' + user.api_token);
    }
    return api_url;
}

/**
* 
*/
export function authUser() {
    const key = 'livrAppUser';
    return {
        persist: (data) => localStorage.setItem(key, JSON.stringify(data)),
        get: () => JSON.parse(localStorage.getItem(key)),
    }
}

/**
* Log out user
*/
export function logout() {
    localStorage.removeItem("livrAppUser");
}

/**
* Get form data
*/
/*export function getFormData(event) {
    const inputs = {};
    const form = event.target;
    const data = new FormData(form);
    for (let name of data.keys()) {
        const input = form.elements[name];
        inputs[name] = input.value;
    }
    return inputs;
}*/

/**
* Date format
*/
export function dateFormat(date) {
    return date;
    /*let dte = new Date(date);
    let dd = dte.getDate();
    var mm = dte.getMonth()+1;
    var yyyy = dte.getFullYear();
    if (dd < 10) dd = '0'+dd;
    if (mm < 10) mm = '0'+mm;
    return dd + '-' + mm + '-' + yyyy*/
}


/**
* Change Language
*/
export function currentLang() {
    const key = 'livrAppLang';
    return {
        persist: (lang) => localStorage.setItem(key, lang),
        get: () => (localStorage.getItem(key) || 'fr'),
    }
}

/**
* Translate
*/ 
export function trans(text) {
    var lang = currentLang().get();
    try {
        var json = require('../../assets/translations/' + lang + '.json');
        var value = json[text];
        if ( typeof value === 'undefined' ) return text;
        return value;
    } catch (err) {
        return text;
    }
}