import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { apiUrl, authUser } from './helpers/Global';

class RegisterLivreur extends Component {

    constructor() {
        super();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            error: null,
            inputs: {'work_type':'self'},
            cities: [],
            cities_areas: [],
            quartiers: []
        };
    }

    componentDidMount() {
        axios.post(apiUrl(`cities`)).then(res => {
            const data = res.data;
            this.setState({
                cities: data.cities,
                cities_areas: data.cities_areas
            });
        });
        if (typeof this.props.setCurrentPath === "function") {
			this.props.setCurrentPath();
		}
    }

    render() {
        const inputs = this.state.inputs || {};

        return (
            <div id='app-root'>
                <div className='content' id='register'>
                    <div className='container'>

                        <div className='page-title'><h2>S'inscrire</h2></div>

                        <div className='text-center btns-grid'>
                            <Link to='/inscription-commercant' replace className='btn btn-grid'>
                                <img src={require('../assets/images/icons/shopping-bag.svg')} className='icon' alt='commerçant' />
                                Compte commerçant
                            </Link>
                            <Link to='/inscription-livreur' replace className='btn btn-grid active'>
                                <img src={require('../assets/images/icons/scooter.svg')} className='icon' alt='livreur' />
                                Compte livreur
                            </Link>
                        </div>

                        { inputs.work_type==='self' && (<div className='alert alert-primary'>Vous devez être un auto-entrepreneur ou une société pour pouvoir vous inscrire.</div>) }

                        <form className='form' onSubmit={this.handleSubmit}>
                            <div className='form-group'>
                                <label>Nom &amp; prénom <small className='red'>(*)</small></label>
                                <input type='text' name='name' className='form-control' onChange={this.inputHandling}  required />
                            </div>
                            <div className='form-group'>
                                <label>E-mail <small className='red'>(*)</small></label>
                                <input type='email' name='email' className='form-control' onChange={this.inputHandling} required />
                            </div>
                            <div className='form-group'>
                                <label>Téléphone <small className='red'>(*)</small></label>
                                <input type='text' name='phone' className='form-control' onChange={this.inputHandling} required />
                            </div>
                            <div className='form-group clearfix'>
                                <div className='col-sm-6 pr-3'>
                                    <label>Ville <small className='red'>(*)</small></label>
                                    { !this.state.city_text ? (
                                        <select className='form-control' name='city_id' onChange={this.inputCityHandling} required>
                                            <option value=''>-- Choisir la ville --</option>
                                            { this.state.cities.map((city) =>
                                                <option value={city.id} key={city.id}>{ city.name }</option>
                                            )}
                                            <option value='-1'>-- Autres --</option>
                                        </select>
                                    ) : (
                                        <input type='text' name='city' className='form-control' onChange={this.inputHandling} placeholder='Saisir la ville' required autoFocus />
                                    ) }
                                </div>
                                <div className='col-sm-6'>
                                    <label>Quartier</label>
                                    { !this.state.city_area_text ? (
                                        <select className='form-control' name='city_area_id' onChange={this.inputAreaHandling}>
                                            <option value=''>-- Toutes les quartiers --</option>
                                            { this.state.quartiers.map((quartier) =>
                                                <option value={quartier.id} key={quartier.id}>{ quartier.name }</option>
                                            )}
                                            <option value='-1'>-- Autres --</option>
                                        </select>
                                    ) : (
                                        <input type='text' name='city_area' className='form-control' onChange={this.inputHandling} placeholder='Saisir la quartier' required autoFocus />
                                    ) }
                                </div>
                            </div>

                            <div className='form-group'>
                                <div className='radio switch m-0'>
                                    <input type='radio' id='work_self' name='work_type' checked={inputs.work_type==='self'?true:false} onChange={this.inputHandling} value='self' />
                                    <label htmlFor='work_self' className='icon'></label>
                                    <label htmlFor='work_self' className='txt'>Je travaille pour mon compte</label>
                                </div>
                            </div>

                            <div className='form-group'>
                                <div className='radio switch m-0'>
                                    <input type='radio' id='work_society' name='work_type' checked={inputs.work_type==='society'?true:false} onChange={this.inputHandling} value='society' />
                                    <label htmlFor='work_society' className='icon'></label>
                                    <label htmlFor='work_society' className='txt'>Je travaille chez une entreprise</label>
                                </div>
                            </div>

                            { inputs.work_type==='self' ? (
                                <React.Fragment>
                                    <div className='form-group'>
                                        <label>ICE <small className='red'>(*)</small></label>
                                        <input type='text' name='ice' className='form-control' onChange={this.inputHandling} required />
                                    </div>
                                    <div className='form-group'>
                                        <label>Numéro CIN <small className='red'>(*)</small></label>
                                        <input type='text' name='cin' className='form-control' onChange={this.inputHandling} required />
                                    </div>
                                    <div className='form-group'>
                                        <label>Recto carte auto-entrepreneur</label>
                                        <input type='file' name='recto_carte_entrepreneur' className='form-control' onChange={this.inputFileHandling} />
                                    </div>
                                    <div className='form-group'>
                                        <label>Verso carte auto-entrepreneur</label>
                                        <input type='file' name='verso_carte_entrepreneur' className='form-control' onChange={this.inputFileHandling} />
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className='form-group'>
                                        <label>Code société <small className='red'>(*)</small></label>
                                        <input type='text' name='society_code' className='form-control' onChange={this.inputHandling} required />
                                    </div>
                                </React.Fragment>
                            )}

                            <div className='form-group'>
                                <label>Mot de passe <small className='red'>(*)</small></label>
                                <input type='password' name='password' className='form-control' onChange={this.inputHandling} required />
                            </div>

                            <div className='form-group'>
								<div className='checkbox'>
									<input type='checkbox' id='conditions' name='conditions' onChange={this.inputHandling} />
									<label htmlFor='conditions' className='icon'></label>
									<label htmlFor='conditions' className='txt'>J'ai lu et j'accepte les <Link to='conditions' target='_blank'>conditions générales d'utilisation</Link>.</label>
								</div>
							</div>

                            { this.state.error ? (<div className='alert alert-danger'>{ this.state.error }</div>) : ('') }

                            <div className='mt-4'>
                                <button type='submit' className='btn w-100' id='btnSubmit'>S'inscrire</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        );
    }

    inputFileHandling = (event) => {
        var inputs = this.state.inputs;
        var target = event.target;
        if (target.files && target.files[0]) {
            var file = target.files[0];
            var extension = file.name.split('.').pop();
            window.loadImage.parseMetaData(file, data => {
                var orientation = 0;
                if (data.exif) {
                    orientation = data.exif.get('Orientation');
                }
                window.loadImage(file, (canvas) => {
                    var base64data = canvas.toDataURL('image/jpeg');
                    inputs[target.name] = base64data;
                    inputs[target.name + '_extension'] = extension;
                    this.setState({ inputs: inputs });
                }, { canvas: true, orientation: orientation});
            });
        }
    }

    inputHandling = (event) => {
        var inputs = this.state.inputs;
        var target = event.target;
        inputs[target.name] = target.value;
        this.setState({ inputs: inputs });
    }

    handleSubmit(event) {
        event.preventDefault();
        var inputs = this.state.inputs;
        inputs['is_livreur'] = 1;
        var btn = document.getElementById('btnSubmit');
        btn.classList.add('loading');
        axios.post(apiUrl(`users/create`), inputs).then(res => {
            const data = res.data;
            if ( data.error ) {
                this.setState({ 
                    error: data.message,
                });
            } else if ( data.user ) {
                authUser().persist(data.user);
                if (typeof this.props.authenticate === "function") {
                    this.props.authenticate();
                }
                this.props.history.push(`/`);
            }
            btn.classList.remove('loading');
        });
    }

    inputCityHandling = (event) => {
        var target = event.target;
        var city_id = parseInt(target.value);
        if ( city_id === -1 ) {
            this.setState({ 
                city_text: 1,
                quartiers: []
            });
        } else {
            var cities_areas = this.state.cities_areas || [];
            var arr = [];
            cities_areas.forEach((area) => {
                if ( area.city_id === city_id ) {
                    arr.push(area);
                }
            });
            this.setState({ quartiers: arr }, function(){
                var inputs = this.state.inputs;
                inputs[target.name] = target.value;
                this.setState({ inputs: inputs });
            });
        }
    }

    inputAreaHandling = (event) => {
        var target = event.target;
        var area_id = parseInt(target.value);
        if ( area_id === -1 ) {
            this.setState({ city_area_text: 1 });
        } else {
            var inputs = this.state.inputs;
            inputs[target.name] = target.value;
            this.setState({ inputs: inputs });
        }
    }

}

export default withRouter(RegisterLivreur);
