import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { apiUrl } from './helpers/Global';

class Conditions extends Component {

    constructor() {
        super();
        this.state = {
            page: {}
        };
    }

    componentDidMount() {
        axios.post(apiUrl(`conditions`)).then(res => {
            const data = res.data;
            this.setState({
                page: data,
            });
        });
    }

    render() {
        var page = this.state.page;
        return (
            <div id='app-root'>
                <div className='content' id='login'>
                    <div className='container-fluid'>
                        <div className='page-title'><h2>{ page.title }</h2></div>
                        { React.createElement('div', { dangerouslySetInnerHTML: { __html: page.content } }) }
                    </div>
                </div>
            </div>
        );
    }

}

export default withRouter(Conditions);
