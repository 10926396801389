import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { apiUrl } from './../helpers/Global';

class CommandCreate extends Component 
{

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            auth_user: props.auth_user,
            vehicleType: 'En moto',
            cities: [],
            cities_areas: [],
            quartiers_loading: [],
            quartiers_delivery: [],
            success: null,
            error: null,
            inputs: {},
            delivery_fees_start: 15,
            app_fees_start: 2,
            show_input_loading_address: false,
            show_input_delivery_address: false
        };
    }

    componentDidMount() {
        var auth_user = this.state.auth_user || {};
        var inputs = this.state.inputs;
        axios.post(apiUrl(`cities`)).then(res => {
            var data = res.data;
            inputs['loading_city_id'] = auth_user.city_id;
            inputs['loading_city_area_id'] = auth_user.city_area_id || 0;
            inputs['delivery_city_id'] = auth_user.city_id;
            inputs['delivery_city_area_id'] = auth_user.city_area_id || 0;
            inputs['delivery_advance_fees'] = true;
            inputs['livreurs'] = 'auto';
            this.setState({
                cities: data.cities,
                cities_areas: data.cities_areas,
                inputs: inputs
            }, function(){
                this.inputCityHandling(auth_user.city_id);
            });
        });
    }

    render() {
        var auth_user = (this.state.auth_user || {});
        var inputs = (this.state.inputs || {});

        if ( auth_user.state !== 1 ) {
            return (
                <div id='command-create'>
                    <div className='page-title'><h2>Créer une commande</h2></div>
                    <p className='text-muted text-center'>Votre compte est en cours de validation...</p>
                </div>
            );
        } else {
            return (
                <div id='command-create'>
                    <div className='page-title'><h2>Créer une demande</h2></div>
                    <form className='form' method='post' onSubmit={this.handleSubmit}>
    
                        <fieldset>
                            <legend>Détails de votre envoi</legend>

                            <div className='form-group text-center btns-grid'>
                                <button type='button' className={`btn btn-grid${ this.state.vehicleType==='En vélo'?' active':''}`} onClick={() => this.changeVehicleType('En vélo')}>
                                    <img src={require('../../assets/images/icons/bicycle.svg')} className='icon' alt='En vélo' />
                                    En vélo
                                </button>
                                <button type='button' className={`btn btn-grid${ this.state.vehicleType==='En moto'?' active':''}`} onClick={() => this.changeVehicleType('En moto')}>
                                    <img src={require('../../assets/images/icons/scooter.svg')} className='icon' alt='En moto' />
                                    En moto
                                </button>
                                <button type='button' className={`btn btn-grid${ this.state.vehicleType==='En voiture'?' active':''}`} onClick={() => this.changeVehicleType('En voiture')}>
                                    <img src={require('../../assets/images/icons/steering-wheel.svg')} className='icon' alt='En voiture' />
                                    En voiture
                                </button>
                            </div>

                            <div className='form-group clearfix'>
                                <div className='col-sm-6 pr-3'>
                                    <label>Ville de départ <small className='red'>(*)</small></label>
                                    <select className='form-control' name='loading_city_id' value={inputs.loading_city_id} onChange={this.inputHandling} required>
                                        { this.state.cities.map((city) =>
                                            <option value={city.id} key={city.id}>{city.name}</option>
                                        )}
                                    </select>
                                </div>
                                <div className='col-sm-6'>
                                    <label>Quartier <small className='red'>(*)</small></label>
                                    <select className='form-control' name='loading_city_area_id' value={inputs.loading_city_area_id} onChange={this.inputHandling} required>
                                        <option value=''>-- Choisir la quartier --</option>
                                        { this.state.quartiers_loading.map((quartier) =>
                                            <option value={quartier.id} key={quartier.id}>{quartier.name}</option>
                                        )}
                                    </select>
                                </div>
                                { !this.state.show_input_loading_address && (
                                    <div className='text-right'><small><a href='/' onClick={(event)=>this.addLoadingAddress(event)}>Ajouter l'adresse de chargement</a></small></div>
                                )}
                            </div>
                            
                            { this.state.show_input_loading_address && (
                                <div className='form-group clearfix'>
                                    <label>Adresse de chargement</label>
                                    <input type='text' name='loading_address' className='form-control' onChange={this.inputHandling} />
                                </div>
                            )}

                            <div className='form-group clearfix'>
                                <div className='col-sm-6 pr-3'>
                                    <label>Ville d'arrivée <small className='red'>(*)</small></label>
                                    <select className='form-control' name='delivery_city_id' value={inputs.delivery_city_id} onChange={this.inputHandling} required>
                                        { this.state.cities.map((city) =>
                                            <option value={city.id} key={city.id}>{ city.name }</option>
                                        )}
                                    </select>
                                </div>

                                <div className='col-sm-6'>
                                    <label>Quartier <small className='red'>(*)</small></label>
                                    <select className='form-control' name='delivery_city_area_id' value={inputs.delivery_city_area_id} defaultValue={auth_user.city_area_id} onChange={this.inputHandling} required>
                                        <option value=''>-- Choisir la quartier --</option>
                                        { this.state.quartiers_delivery.map((quartier) =>
                                            <option value={quartier.id} key={quartier.id}>{quartier.name}</option>
                                        )}
                                    </select>
                                </div>
                                { !this.state.show_input_delivery_address && (
                                    <div className='text-right'><small><a href='/' onClick={(event)=>this.addDeliveryAddress(event)}>Ajouter l'adresse de livraison</a></small></div>
                                )}
                            </div>

                            { this.state.show_input_delivery_address && (
                                <div className='form-group clearfix'>
                                    <label>Adresse de livraison</label>
                                    <input type='text' name='delivery_address' className='form-control' onChange={this.inputHandling} />
                                </div>
                            )}
                            
                            <div className='form-group clearfix'>
                                <div className='col-sm-6 pr-3'>
                                    <label>Total de la commande <small className='red'>(*)</small></label>
                                    <input type='text' name='total' className='form-control' onChange={this.inputHandling} required />
                                </div>
                                <div className='col-sm-6'>
                                    <label>Heure de départ <small className='red'>(*)</small></label>
                                    <select className='form-control' name='delivery_time' onChange={this.inputHandling} required>
                                        <option>15 minutes</option>
                                        <option>30 minutes</option>
                                        <option>45 minutes</option>
                                        <option>1 heure</option>
                                        <option>1 heure 30 minutes</option>
                                        <option>2 heures</option>
                                    </select>
                                </div>
                            </div>
							
							<div className='form-group clearfix'>
                                <div className='col-sm-6 pr-3'>
                                    <label>Frais de livraison <small className='red'>(*)</small></label>
                                    <select className='form-control' name='delivery_fees' onChange={this.inputHandling} required>
                                        { this.getSelectOptions(this.state.delivery_fees_start, 30) }
                                    </select>
                                </div>
                            </div>

                            <div className='form-group'>
                                <div className='checkbox switch m-0'>
                                    <input type='checkbox' id='delivery_advance_fees' name='delivery_advance_fees' checked={inputs.delivery_advance_fees ? true : false} onChange={this.inputHandling} />
                                    <label htmlFor='delivery_advance_fees' className='icon'></label>
                                    <label htmlFor='delivery_advance_fees' className='txt'>Le livreur doit avancer les frais de la livraison</label>
                                </div>
                            </div>

                            <div className='form-group'>
                                <label>Détail de la livraison</label>
                                <textarea name='details' className='form-control' rows='5' onChange={this.inputHandling} />
                            </div>

                            <div className='clearfix'><hr/></div>

                            <div className='form-group'>
                                <div className='radio switch m-0'>
                                    <input type='radio' id='livreurs1' name='livreurs' value='auto' checked={inputs.livreurs==='auto' ? true : false} onChange={this.inputHandling} />
                                    <label htmlFor='livreurs1' className='icon'></label>
                                    <label htmlFor='livreurs1' className='txt'>Laisser l'application me trouver un livreur</label>
                                </div>
                            </div>

                            <div className='form-group'>
                                <div className='radio switch m-0'>
                                    <input type='radio' id='livreurs2' name='livreurs' value='select' checked={inputs.livreurs==='select' ? true : false} onChange={this.inputHandling} />
                                    <label htmlFor='livreurs2' className='icon'></label>
                                    <label htmlFor='livreurs2' className='txt'>Choisir les livreurs</label>
                                </div>
                            </div>

                            { (inputs.livreurs==='select') && (
                                <React.Fragment>

                                    <fieldset className='clearfix mt-4'>
                                        <legend className='m-0'>Mes livreurs</legend>
                                        { this.state.livreurs_mylist ? (
                                        <table className='table m-0 small'>
                                            <tbody>
                                                { this.state.livreurs_mylist.map(livreur =>
                                                <tr key={livreur.id}>
                                                    <td>
                                                        <div className='checkbox switch m-0'>
                                                            <input type='checkbox' id={`livreurid${livreur.id}`} name={`livreurs_ids`} onChange={this.inputHandling} value={livreur.id} />
                                                            <label htmlFor={`livreurid${livreur.id}`} className='icon'></label>
                                                            <label htmlFor={`livreurid${livreur.id}`} className='txt'>{livreur.name}</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        ) : (<p className='text-muted'>Aucun livreur disponible !</p>)}
                                    </fieldset>

                                    <fieldset className='clearfix mt-4'>
                                        <legend className='m-0'>Mes favoris</legend>
                                        { (this.state.livreurs_favoris) ? (
                                        <table className='table m-0 small'>
                                            <tbody>
                                                { this.state.livreurs_favoris.map(livreur =>
                                                <tr key={livreur.id}>
                                                    <td>
                                                        <div className='checkbox switch m-0'>
                                                            <input type='checkbox' id={`livreurid${livreur.id}`} name={`livreurs_ids`} onChange={this.inputHandling} value={livreur.id} />
                                                            <label htmlFor={`livreurid${livreur.id}`} className='icon'></label>
                                                            <label htmlFor={`livreurid${livreur.id}`} className='txt'>{livreur.name}</label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                )}
                                            </tbody>
                                        </table>
                                        ) : (<p className='text-muted'>Aucun livreur disponible !</p>)}
                                    </fieldset>
                                    
                                </React.Fragment>
                            )}

                        </fieldset>
    
                        { this.state.error ? (<div className='alert alert-danger'>{ this.state.error }</div>) : '' }
                        { this.state.success ? (<div className='alert alert-success'>{ this.state.success }</div>) : '' }
    
                        <div className='mt-4'>
                            <button type='submit' className='btn w-100' id='btnSubmit'>Créer</button>
                        </div>
                    </form>
                </div>
            );
        }
        
    }

    addLoadingAddress = (event) => {
        event.preventDefault();
        this.setState({ show_input_loading_address: true });
    }

    addDeliveryAddress = (event) => {
        event.preventDefault();
        this.setState({ show_input_delivery_address: true });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ success: null });
        var inputs = this.state.inputs;
        inputs['vehicle_type'] = this.state.vehicleType;
        if ( !inputs.city_id ) inputs['city_id'] = this.state.city_id;
        if ( !inputs.delivery_fees ) inputs['delivery_fees'] = this.state.delivery_fees_start;
        //if ( !inputs.app_fees ) inputs['app_fees'] = this.state.app_fees_start;
        if ( !inputs.delivery_time ) inputs['delivery_time'] = '15 minutes';
        const form = event.target;
        var btn = document.getElementById('btnSubmit');
        btn.classList.add('loading');
        axios.post(apiUrl(`commands/create`), inputs).then(res => {
            const data = res.data;
            if ( data.error ) {
                this.setState({
                    error: data.message,
                    success: null
                });
            } else {
                this.setState({
                    success: data.message,
                    error: null
                }, function(){
                    form.reset();
                    inputs['delivery_advance_fees'] = true;
                    inputs['livreurs'] = 'auto';
                    this.setState({ inputs: inputs });
                });
            }
            btn.classList.remove('loading');
        });
    }

    getSelectOptions = (nbr1, nbr2) => {
        let options = [];
        for (let i = nbr1; i <= nbr2; i++) {
            options.push(<option value={i} key={i}>{`${i} Dh`}</option>)
        }
        return options;
    }

    changeVehicleType = (type) => {
        this.setState({ vehicleType: type });
    }

    inputHandling = (event) => {
        var inputs = this.state.inputs;
        var target = event.target;
        if ( target.name === 'livreurs_ids' ) {
            var livreurs = inputs[target.name] || [];
            if ( target.checked ) {
                livreurs.push(target.value);
            } else {
                const liv_index = livreurs.findIndex(id => (id === target.value));
                livreurs.splice(liv_index, 1);
            }
            inputs[target.name] = livreurs;
        } else {
            inputs[target.name] = target.type==='checkbox' ? target.checked : target.value;
        }
        this.setState({ inputs: inputs });
        if ( target.name === 'loading_city_id' ) {
            this.inputCityHandling(target.value, 'loading');
        } else if ( target.name === 'delivery_city_id' ) {
            this.inputCityHandling(target.value, 'delivery');
        } else if ( target.name === 'livreurs' ) {
            if ( target.value === 'select' ) {
                this.getLivreurs();
            } else {
                this.setState({ livreurs_mylist: [], livreurs_favoris: [] });
            }
        }
    }

    getLivreurs = () => {
        const auth_user = this.state.auth_user;
        axios.get(apiUrl(`users`)+'&available=true&type=livreur&society_id='+auth_user.id).then(res => {
            const data = res.data;
            this.setState({
                livreurs_mylist: data.mylist,
                livreurs_favoris: data.favoris
            });
        });
    }

    inputCityHandling = (city_id, typ=null) => {
        var cities_areas = this.state.cities_areas || [];
        var quartiers = [];
        cities_areas.forEach((area) => {
            if ( parseInt(area.city_id) === parseInt(city_id) ) {
                quartiers.push(area);
            }
        });
        if ( typ === 'loading' ) {
            this.setState({ quartiers_loading: quartiers });
        } else if ( typ === 'delivery' ) {
            this.setState({ quartiers_delivery: quartiers });
        } else {
            this.setState({ quartiers_loading: quartiers, quartiers_delivery: quartiers });
        }
    }

}

export default withRouter(CommandCreate);
