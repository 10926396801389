import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { apiUrl, dateFormat } from './../helpers/Global';

class CommandDetails extends Component
{

    constructor(props) {
        super(props);
        this.handleMessageSubmit = this.handleMessageSubmit.bind(this);
        this.state = {
            auth_user: props.auth_user,
            command_id: props.match.params.id,
            command: {},
            command_states: [],
            form_message: false,
            livreurs: [],
            command_state_id: null,
            inputs: {},
            livreurs_favoris: []
        };
    }
    
    componentDidMount() {
        //var auth_user = this.state.auth_user;

        axios.get(apiUrl(`commands/show/` + this.state.command_id)).then(res => {
            const data = res.data;
            if ( data.error ) {
                this.setState({ error: data.message });
            } else {
                var command = data.command;
                var livreurs_favoris = Object.values(data.favoris) || [];
                this.setState({
                    command: command,
                    command_states: data.states,
                    command_state_id: data.command.state_id,
                    livreurs_favoris: livreurs_favoris,
                    text_favoris_button: (command.livreur_id && livreurs_favoris.includes(command.livreur_id.toString()) ? 'Supprimer de mes favoris' : 'Ajouter à mes favoris'),
                });
            }
        });

        /*if ( auth_user.id === 1 ) {
            axios.get(apiUrl(`users`)+'&available=true&type=livreur&command_id='+this.state.command_id).then(res => {
                const data = res.data;
                this.setState({ livreurs: data });
            });
        }*/
    }

    render() {
        var auth_user = this.state.auth_user;
        var command = this.state.command;
        var command_history = command.history ? command.history : [];
        var command_messages = command.messages ? command.messages : [];
        
        var is_command_livreur = command.livreur_id === auth_user.id;
        var is_command_owner = command.user_id === auth_user.id;
        var livreur = command.livreur || {};
        
        return (
            <div id='command-details'>
                <div className='page-title'><h2><small>Commande N° : </small> &nbsp;{command.num}</h2></div>
                <table className='infos-table' style={{ marginTop: '15px' }}>
                    <tbody>
                        <tr>
                            <td>Adresse du chargement</td>
                            <td>{command.loading_address} {command.loading_city_area}, {command.loading_city}</td>
                        </tr>
                        <tr>
                            <td>Adresse du livraison</td>
                            <td>{command.delivery_address} {command.delivery_city_area}, {command.delivery_city}</td>
                        </tr>
                        <tr>
                            <td>Frais de livraison</td>
                            <td>{command.delivery_fees ? (command.delivery_fees+' Dh') : '-'}</td>
                        </tr>
                        <tr>
                            <td>Total de la commande</td>
                            <td>{command.total ? (command.total+' Dh') : '-'}</td>
                        </tr>
                        <tr>
                            <td>Délai de livraison</td>
                            <td>{command.delivery_time}</td>
                        </tr>
                        
                        { command.details &&
                        <tr>
                            <td>Détail de la livraison</td>
                            <td>{command.details}</td>
                        </tr>
                        }

                        { (this.state.error || this.state.success) &&
                            <tr>
                                <td colSpan='2'>
                                    { this.state.error ? (<div className='alert alert-danger'>{ this.state.error }</div>) : '' }
                                    { this.state.success ? (<div className='alert alert-success'>{ this.state.success }</div>) : '' }
                                </td>
                            </tr>
                        }

                    </tbody>
                </table>

                { is_command_livreur && (
                    <div className='clearfix'>
                        <hr/>
                        <h3>Etat de la commande</h3>
                        <form method='post'>
                            { (command.status === 'delivered') ? (
                                <p className="mt-0">La commande est livrée.</p>
                            ) : (
                                <div className='form-group text-center btns-grid'>
                                    { this.state.command_states.map((state) =>
                                        <button type='button' className={`btn btn-grid${ this.state.command_state_id===state.id?' active':''}`} key={state.id} onClick={() => this.changeCommandeState(state)}>{state.name}</button>
                                    )}
                                </div>
                            ) }
                        </form>
                    </div>
                ) }

                { (is_command_owner || is_command_livreur) && (
                    <div className='clearfix'>
                        <div className='messaging-section'>
                            <h3>Messages</h3>
                            { command_messages.map((msg) => 
                                <div className={'message-container clearfix' + (auth_user.id === msg.user_id ? ' sender' : '')} key={msg.id}>
                                    <div className='form-group message' key={msg.id}>
                                        { msg.description }
                                        <small className='date'>{ dateFormat(msg.created_at) }</small>
                                    </div>
                                    <small className="name">{ msg.user_name }</small>
                               </div>
                            )}

                            { !this.state.form_message && (
                                <div className='form-group text-center'>
                                    <button className='btn btn-xs' onClick={() => this.showFormMessage(true)}>Nouveau</button>
                                </div>
                            )}

                            { this.state.form_message && (
                                <form method='post' onSubmit={this.handleMessageSubmit}>
                                    <div className='form-group'>
                                        <textarea type='text' name='description' rows='3' className='form-control' placeholder='Votre message ici ...' required onChange={this.inputHandling}></textarea>
                                    </div>
                                    <button type='submit' className='btn btn-xs mr-4'>Envoyer</button>
                                    <button type='button' className='btn btn-default btn-xs' onClick={() => this.showFormMessage(false)}>Annuler</button>
                                </form>
                            )}
                        </div>
                            
                        {is_command_owner && (
                            <React.Fragment>
                                <h3>Historique</h3>
                                <table className='table small'>
                                    <tbody>
                                        { command_history.map((action, key) =>
                                        <tr key={action.id}>
                                            <td>{ action.description }<br/><small>{ dateFormat(action.created_at) }</small></td>
                                        </tr>
                                        )}
                                    </tbody>
                                </table>
                            </React.Fragment>
                        )}

                    </div>
                )}

                { (is_command_owner && livreur.id) ? (
                    <div className='clearfix'>
                        <div className='messaging-section'>
                            <h3>Livreur:</h3>
                            { livreur.name }
                            <div className='form-group text-center'>
                                <button type='button' className='btn btn-xs' id='btnLivreurFavoris' onClick={()=>this.livreurFavoris()}>{ this.state.text_favoris_button }</button>
                            </div>
                        </div>
                    </div>
                ) : (auth_user.user_type==='pro') && (
                    <React.Fragment>
                        <div className='clearfix'><hr/></div>
                        <div className='form-group'>
                            <div className='checkbox switch m-0'>
                                <input type='checkbox' id='livreurs' name='livreurs' onChange={ this.inputLivreursHandling} />
                                <label htmlFor='livreurs' className='icon'></label>
                                <label htmlFor='livreurs' className='txt'>Choisir un autre livreur</label>
                            </div>
                        </div>

                        { this.state.show_livreurs && (
                            <div className='form'>
                                { this.state.error_livreur && (<div className='alert alert-danger'>{this.state.error_livreur}</div>) }
                                <fieldset className='clearfix mt-4'>
                                    <legend className='m-0'>Mes livreurs</legend>
                                    { this.state.livreurs_mylist ? (
                                    <table className='table m-0 small'>
                                        <tbody>
                                            { this.state.livreurs_mylist.map(livreur =>
                                            <tr key={livreur.id}>
                                                <td>{livreur.name}</td>
                                                <td>
                                                    { livreur.command_action ? livreur.command_action : (
                                                        <button type='button' className='btn btn-xs' onClick={(event) => this.notifyLivreur(event, livreur.id)}>Notifier</button>
                                                    )}
                                                </td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    ) : (<p className='text-muted'>Aucun livreur disponible !</p>)}
                                </fieldset>
                                <fieldset className='clearfix mt-4'>
                                    <legend className='m-0'>Mes favoris</legend>
                                    { (this.state.livreurs_favoris) ? (
                                    <table className='table m-0 small'>
                                        <tbody>
                                            { this.state.livreurs_favoris.map(livreur =>
                                            <tr key={livreur.id}>
                                                <td>{livreur.name}</td>
                                                <td>
                                                    { livreur.command_action ? livreur.command_action : (
                                                        <button type='button' className='btn btn-xs' onClick={(event) => this.notifyLivreur(event, livreur.id)}>Notifier</button>
                                                    )}
                                                </td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    ) : (<p className='text-muted'>Aucun livreur disponible !</p>)}
                                </fieldset>
                                
                            </div>
                        )}

                    </React.Fragment>
                )}

            </div>
        );
    }

    inputLivreursHandling = (event) => {
        const target = event.target;
        if ( target.checked ) {
            this.setState({ show_livreurs: true }, function(){
                this.getLivreurs();
            });
        } else {
            this.setState({ show_livreurs: false });
        }
    }

    getLivreurs = () => {
        const auth_user = this.state.auth_user;
        const command_id = this.state.command_id;
        axios.get(apiUrl(`users`)+'&available=true&type=livreur&society_id='+auth_user.id+'&command_id='+command_id).then(res => {
            const data = res.data;
            this.setState({
                livreurs_mylist: data.mylist,
                livreurs_favoris: data.favoris
            });
        });
    }

    livreurFavoris = () => {
        var btn = document.getElementById('btnLivreurFavoris');
        btn.classList.add('loading');
        var inputs = {};
        var command = this.state.command;
        inputs['command_id'] = command.id;
        inputs['livreur_id'] = command.livreur_id;
        axios.post(apiUrl(`commands/livreurfavoris`), inputs).then(res => {
            const data = res.data;
            if ( data.error === 0 ) {
                if ( data.action === 'removed' ) {
                    this.setState({ text_favoris_button: 'Ajouter à mes favoris' });
                } else {
                    this.setState({ text_favoris_button: 'Supprimer de mes favoris' });
                }
            }
            btn.classList.remove('loading');
        });
    }

    changeCommandeState = (state) => {
        this.setState({ command_state_id: state.id }, function(){
            const inputs = {};
            inputs['command_id'] = this.state.command_id;
            inputs['state_id'] = this.state.command_state_id;
            axios.post(apiUrl(`commands/state`), inputs).then(res => {
                const data = res.data;
                if ( data.error === 0 ) {
                    if ( data.history ) {
                        let command = this.state.command;
                        command.history = data.history;
                        command.status = state.slug;
                        this.setState({ command: command });
                    }
                }
            });
        });
    }

    notifyLivreur = (event, v) => {
        var target = event.target;
        var inputs = {};
        inputs['livreur_id'] = v;
        inputs['command_id'] = this.state.command_id;
        axios.post(apiUrl(`commands/notifylivreur`), inputs).then(res => {
            const data = res.data;
            if ( data.error === 0 ) {
                if (typeof target !== 'undefined') target.remove();
                this.setState({ error_livreur: null });
            } else if ( data.message ) {
                this.setState({ error_livreur: data.message });
            }
        });
    }

    showFormMessage = (v) => {
        this.setState({ form_message: v })
    }

    handleMessageSubmit(event) {
        event.preventDefault();
        const inputs = this.state.inputs;
        inputs['command_id'] = this.state.command_id;
        const form = event.target;
        axios.post(apiUrl(`commands/message`), inputs).then(res => {
            const data = res.data;
            if ( data.messages ) {
                let command = this.state.command;
                command.messages = data.messages;
                this.setState({ command: command });
            }
            if ( !data.error ) {
                this.setState({ 
                    form_message: false
                }, function(){
                    form.reset();
                });
            }
        });
    }

    /*saveAction = (v) => {
        this.setState({ error: '', success: '' });
        const inputs = {};
        inputs['command_id'] = this.state.command_id;
        inputs['command_accept'] = v;
        axios.post(apiUrl(`deliverers/command/accept`), inputs).then(res => {
            const data = res.data;
            if ( data.error ) {
                this.setState({ error: data.message, success: '' });
            } else if ( data.message ) {
                this.setState({ success: data.message, error: '' });
            }
            if ( data.command ) {
                this.setState({ command: data.command, command_state_id: data.command.state_id });
            }
        });
    }*/

    inputHandling = (event) => {
        var inputs = this.state.inputs;
        var target = event.target;
        inputs[target.name] = target.value;
        this.setState({ inputs: inputs });
    }

}

export default withRouter(CommandDetails);
