import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { apiUrl } from './helpers/Global';

class Contact extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            inputs: {},
            error: null,
            success: null
        };
    }

    componentDidMount() {
		if (typeof this.props.setCurrentPath === "function") {
			this.props.setCurrentPath();
		}
	}

    render() {
		return (
			<div id='app-root'>
                <div id='support-new' className='content'>
                    <div className='container'>
                        <div className='page-title'><h2>Contactez-nous</h2></div>
                        <form className='form' method='post' onSubmit={this.handleSubmit}>
                            <div className='form-group'>
                                <label>Nom complet <small className='red'>(*)</small></label>
                                <input type='text' name='name' className='form-control' onChange={this.inputHandling} required />
                            </div>
                            <div className='form-group'>
                                <label>Email <small className='red'>(*)</small></label>
                                <input type='email' name='email' className='form-control' onChange={this.inputHandling} required />
                            </div>
                            <div className='form-group'>
                                <label>Sujet <small className='red'>(*)</small></label>
                                <input type='text' name='subject' className='form-control' onChange={this.inputHandling} required />
                            </div>
                            <div className='form-group'>
                                <label>Message <small className='red'>(*)</small></label>
                                <textarea name='message' className='form-control' rows='6' onChange={this.inputHandling} required></textarea>
                            </div>
                            { this.state.error ? (<div className='alert alert-danger'>{ this.state.error }</div>) : '' }
                            { this.state.success ? (<div className='alert alert-success'>{ this.state.success }</div>) : '' }

                            <button type='submit' className='btn w-100'>Envoyer</button>
                        </form>
                    </div>
                </div>
			</div>
		);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ success: ''});
        var inputs = this.state.inputs;
        const form = event.target;
        axios.post(apiUrl(`contact`), inputs).then(res => {
            const data = res.data;
            if ( data.error ) {
                this.setState({ 
                    error: data.message, 
                    success: '' 
                });
            } else {
                this.setState({ 
                    success: data.message, 
                    error: '' 
                }, function(){
                    form.reset();
                });
            }
        });
    }

    inputHandling = (event) => {
        var inputs = this.state.inputs;
        var target = event.target;
        inputs[target.name] = target.value;
        this.setState({ inputs: inputs });
    }

}

export default withRouter(Contact);
